<template>
  <div>
    <div :class="viewMode == true ? 'grayscale' : ''">
      <div class="row col-12 cursor-pointer">
        <div class="table-responsive">
          <table class="table table-custom table-hover">
            <thead class="text-center">
              <tr>
                <td class="no-line dateStartCell cursor-default">{{ selectedStartDate === '01/01'?'Anual': selectedStartDate}}</td>
                <th
                  v-for="(dato, index) of datos"
                  :key="index"
                  :id="`${id}-embalse-${index}`"
                  @mouseenter="columnHover(index, true)"
                  @mouseleave="columnHover(index, false)"
                >
                  <div class="cursor-default" style="width: 120px">
                    {{ dato.Nombre_Embalse }}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center">
                <th class="align-middle cursor-default">Capacidad</th>
                <td
                  v-for="(dato, index) of datos"
                  :key="index"
                  :id="`${id}-capacidad-${index}`"
                  @mouseenter="columnHover(index, true)"
                  @mouseleave="columnHover(index, false)"
                >
                  <span class="cursor-default"
                    >{{ dato.capacidad.toFixed(3) }} hm<sup>3</sup></span
                  >
                </td>
              </tr>
              <tr>
                <th class="align-middle cursor-default">Vol. min.</th>
                <td
                  v-for="(dato, index) of datos"
                  :key="index"
                  :id="`${id}-volMin-${index}`"
                  @mouseenter="columnHover(index, true)"
                  @mouseleave="columnHover(index, false)"
                >
                  <div class="row">
                    <div class="col mb-2">
                      <input
                        :disabled="viewMode == true ? true : false"
                        type="text"
                        @input="calculateVol(index, false, $event)"
                        @blur="checkEmptyPercent(index, false, $event)"
                        class="inputVolume"
                        :id="`${id}-volMinPercent-${index}`"
                      /><span> %</span>
                    </div>
                    <div class="col">
                      <input
                        :disabled="viewMode == true ? true : false"
                        type="text"
                        v-model="dato[selectorFecha].volMin"
                        @input="calculatePercent(index, false, $event)"
                        @blur="checkEmptyVolume(index, false, $event)"
                        class="inputVolume"
                      /><span> hm<sup>3</sup></span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="align-middle cursor-default">Vol. max.</th>
                <td
                  v-for="(dato, index) of datos"
                  :key="index"
                  :id="`${id}-volMax-${index}`"
                  @mouseenter="columnHover(index, true)"
                  @mouseleave="columnHover(index, false)"
                >
                  <div class="row">
                    <div class="col-12 mb-2">
                      <input
                        :disabled="viewMode == true ? true : false"
                        type="text"
                        @input="calculateVol(index, true, $event)"
                        @blur="checkEmptyPercent(index, true, $event)"
                        class="inputVolume"
                        :id="`${id}-volMaxPercent-${index}`"
                      /><span> %</span>
                    </div>
                    <div class="col-12">
                      <input
                        :disabled="viewMode == true ? true : false"
                        type="text"
                        v-model="dato[selectorFecha].volMax"
                        @input="calculatePercent(index, true, $event)"
                        @blur="checkEmptyVolume(index, true, $event)"
                        class="inputVolume"
                      /><span> hm<sup>3</sup></span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      //Array que contiene el orden de los embalses según el standar de Emasesa
      list : [
        "Aracena","Zufre","La Minilla","El Gergal","Los Melonares","Cala"
      ],
      //Array de datos locales (inicializado con valores test)
      datos: [
        {
          embalse: "Aracena",
          capacidad: 200,
          "01/01": { volMin: 30, volMax: 60 },
          "01/11": { volMin: 30, volMax: 60 },
        },
        {
          embalse: "Zufre",
          capacidad: 250,
          "01/01": { volMin: 90, volMax: 180 },
          "01/11": { volMin: 90, volMax: 180 },
        },
        {
          embalse: "La Minilla",
          capacidad: 400,
          "01/01": { volMin: 185, volMax: 320 },
          "01/11": { volMin: 185, volMax: 320 },
        },
        {
          embalse: "Cala",
          capacidad: 620,
          "01/01": { volMin: 260, volMax: 490 },
          "01/11": { volMin: 260, volMax: 490 },
        },
        {
          embalse: "El Gergal",
          capacidad: 380,
          "01/01": {
            volMin: 100,
            volMax: 260,
          },
          "01/11": {
            volMin: 100,
            volMax: 260,
          },
        },
        {
          embalse: "Los Melonares",
          capacidad: 1400,
          "01/01": {
            volMin: 470,
            volMax: 830,
          },
          "01/11": {
            volMin: 470,
            volMax: 830,
          },
        },
      ],
    };
  },
  components: {},
  props: {
    id: {
      type: String,
      default: ""
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    reusePlan: {
      type: Boolean,
      default: false,
    },
    selectedStartDate: {
      type: String,
      default: "",
    },
  },
  computed: {
    //Importamos la variable reactiva de vuex del año seleccionado por el usuario
    ...mapState("planner", [
      "v_capacity",
      "v_reusePlan",
    ]),
    selectorFecha: function (val) {
      return this.selectedStartDate != "" ? this.selectedStartDate : "";
    },
  },
  watch: {
    reusePlan: async function (newVal, oldVal) {
      if (newVal == true) {
        //Populamos el array de datos locales con los datos remotos
        await this.populateLocalData(this.v_reusePlan.volumes);
        //Una vez tenemos instanaciados los datos, procedemos a calcular los porcentajes iniciales
        await this.calculateInitialPercents();
      }
    },

    selectedStartDate: function (newVal, oldVal) {
      if (newVal) {
        return newVal;
      }
    },
  },
  methods: {
    //Importamos el método de vuex para actualizar los datos de volúmenes
    ...mapActions("planner", ["v_updCapacity"]),

    //Metodo de trigger Pouplate
    async populateFromExt(data){
      //Usamos la función creada para ordenar los embalses de acuerdo al standar de Emasesa
      data=this.orderEmbalses(data, this.list, 'Nombre_Embalse');
        await this.populateLocalData(this.copyObject(data));
        //console.log(data);
        //Una vez tenemos instanaciados los datos, procedemos a calcular los porcentajes iniciales
        await this.calculateInitialPercents();
    },

    /**
     * Método para sustituir los datos locales (test) por los datos remotos
     * @param {Object} data Datos remotos provenientes de la base de datos
     */
    async populateLocalData(data) {
      if(data){
        //Recorremos array de datos remotos
      for await (let item of data){
        item.capacidad = parseFloat(item.Capacidad)
      }
      this.datos = data
      }
    },

    //Método para calcular los porcentajes iniciales
    calculateInitialPercents() {
      //Recorremos con un for clásico el array de datos locales ya que necesitamos operar con el índice
      for (let index = 0; index < this.datos.length; index++) {

        /**
         * Instanciamos el valor de los porcentajes obteniendo el elemento del DOM referenciando el id con el índice
         * e insertando el valor como la división del volumen correspondiente entre la capacidad y luego multiplicado por 100
         */
        document.getElementById(`${this.id}-volMinPercent-${index}`).value = (
          (this.datos[index][this.selectedStartDate].volMin / this.datos[index].capacidad) *
          100
        ).toFixed(3);
        document.getElementById(`${this.id}-volMaxPercent-${index}`).value = (
          (this.datos[index][this.selectedStartDate].volMax / this.datos[index].capacidad) *
          100
        ).toFixed(3);
      }
      //IMPORTANTE: ACTUALIZAR DATOS DE VOLÚMENES EN VUEX
      ///this.v_updVolumes(this.datos);
      //this.$emit('updateVolumesValues', index, this.selectedStartDate, this.datos[index][this.selectedStartDate])
    },

    /**
     * Método para calcular el volumen máximo o mínimo en función del porcentaje máximo o mínimo
     * (Asociado al evento input de los porcentajes)
     * @param {Number} index índice del array de datos que hay que modificar
     * @param {Boolean} mode indica si se ha de modificar el maximo (true) o el mínimo (false)
     * @param {InputEvent} e evento de tipo input
     */
    calculateVol(index, mode, e) {
      //Primero detectamos si se ha introducido un caracter prohibido
      if (this.isForbiddenChar(e.data)) {
        //Si es un caracter prohibido eliminamos todo el contenido del input
        //De esta manera nos cercioramos de que no pueda introducir caracteres probihidos en posiciones intermedias
        e.target.value = null;
      } else {
        //Guardamos el valor del input en una variable local al método
        let percent = Number.parseFloat(e.target.value);
        //Comprobamos que el dato sea numérico: IMPORTANTE esta comprobación se hace con e.target.value
        if (!isNaN(e.target.value)) {
          //Comprobamos que el porcentaje no supere 100
          if (percent > 100) {
            //En caso de superarlo lo establecemos a 100
            percent = 100;
            e.target.value = "100";
          }
          //Guardamos en variable local al método la capacidad del embalse según el índice
          let capacidad = this.datos[index].capacidad;
          //Calculamos el volumen solicitado segun el mode
          if (mode) {
            this.datos[index][this.selectedStartDate].volMax = parseFloat(
              ((capacidad * percent) / 100).toFixed(3)
            );
            //En caso de que hayamos vaciado el contenido del input, hay que evitar el NaN
            this.datos[index][this.selectedStartDate].volMax = isNaN(this.datos[index][this.selectedStartDate].volMax)
              ? 0
              : this.datos[index][this.selectedStartDate].volMax;
          } else {
            this.datos[index][this.selectedStartDate].volMin = parseFloat(
              ((capacidad * percent) / 100).toFixed(3)
            );
            //En caso de que hayamos vaciado el contenido del input, hay que evitar el NaN
            this.datos[index][this.selectedStartDate].volMin = isNaN(this.datos[index][this.selectedStartDate].volMin)
              ? 0
              : this.datos[index][this.selectedStartDate].volMin;
          }
        } else {
          /**
           * Si el dato no es numérico borramos el valor
           * (caso de introducir un punto al principio o 2 puntos seguidos)
           */
          e.target.value = null;
        }
      }
      //IMPORTANTE: ACTUALIZAR DATOS DE VOLÚMENES EN VUEX
      ///this.v_updVolumes(this.datos);
      this.$emit('updateVolumesValues', index, this.selectedStartDate, this.datos[index][this.selectedStartDate])
    },

    /**
     * Método para calcular el porcentaje máximo o mínimo en función del volumen máximo o mínimo
     * (Asociado al evento input de los volúmenes)
     * @param {Number} index índice del array de datos que hay que modificar
     * @param {Boolean} mode indica si se ha de modificar el maximo (true) o el mínimo (false)
     * @param {InputEvent} e evento de tipo input
     */
    calculatePercent(index, mode, e) {
      //Primero detectamos si se ha introducido un caracter prohibido
      if (this.isForbiddenChar(e.data)) {
        //Si es un caracter prohibido eliminamos todo el contenido del input
        //De esta manera nos cercioramos de que no pueda introducir caracteres probihidos en posiciones intermedias
        e.target.value = null;
        /**
         * ¡OJO!
         * Para revertir el cambio reactivo que se habrá desencadenado tenemos que
         * reasignar el valor del input al valor de nuestro array de datos locales
         * (ver explicación fina)
         */
        if (mode) {
          this.datos[index][this.selectedStartDate].volMax = parseFloat(e.target.value);
        } else {
          this.datos[index][this.selectedStartDate].volMin = parseFloat(e.target.value);
        }
      } else {
        //Guardamos el valor del input en una variable local al método
        let volume = parseFloat(e.target.value);
        //Guardamos en variable local al método la capacidad del embalse según el índice
        let capacidad = parseFloat(this.datos[index].capacidad);
        //Comprobamos que el dato sea numérico IMPORTANTE esta comprobación se hace con e.target.value
        if (!isNaN(e.target.value)) {
          //Comprobamos que el volumen no supere a la capacidad
          if (volume > capacidad) {
            //Si el volumen supera a la capacidad le asignamos el valor de la capacidad
            volume = capacidad;
            e.target.value = parseFloat(`${capacidad}`);
            /**
             * ¡OJO!
             * Para realizar una reasignación efeciva tenemos que hacerlo también con el
             * dato local (ver explicación fina)
             */
            if (mode) {
              this.datos[index][this.selectedStartDate].volMax = parseFloat(volume);
            } else {
              this.datos[index][this.selectedStartDate].volMin = parseFloat(volume);
            }
          }
          //Calculamos el porcentaje solicitado segun el mode y lo introducimos con el DOM en el elemento correspondiente
          if(e.data != '.'){
          if (mode) {
            let volMax = parseFloat(
              (this.datos[index][this.selectedStartDate].volMax / this.datos[index].capacidad) * 100
            ).toFixed(3);
            document.getElementById(`${this.id}-volMaxPercent-${index}`).value = volMax;
            this.datos[index][this.selectedStartDate].volMax = parseFloat(e.target.value);
          } else {
            let volMin = parseFloat(
              (this.datos[index][this.selectedStartDate].volMin / this.datos[index].capacidad) * 100
            ).toFixed(3);
            document.getElementById(`${this.id}-volMinPercent-${index}`).value = volMin;
            this.datos[index][this.selectedStartDate].volMin = parseFloat(e.target.value);
          }
          }
        } else {
          /**
           * Si el dato no es numérico borramos el valor
           * (caso de introducir un punto al principio o 2 puntos seguidos)
           */
          e.target.value = null;
          //En este caso tenemos también que borrar a parte el valor del dato local
          if (mode) {
            this.datos[index][this.selectedStartDate].volMax = null;
          } else {
            this.datos[index][this.selectedStartDate].volMin = null;
          }
        }
        //IMPORTANTE: ACTUALIZAR DATOS DE VOLÚMENES EN VUEX
        ///this.v_updVolumes(this.datos);
        this.$emit('updateVolumesValues', index, this.selectedStartDate, this.datos[index][this.selectedStartDate])

        /**
         * EXPLICACIÓN FINAL:
         * Este método es un tanto especial porque opera según el valor del input
         * que a su vez está asociado bidireccionalmente a un modelo local de datos mediante
         * VUE. Los cambios realizados en el valor del input obtenido directamente del
         * DOM, NO SON REACTIVOS CON RESPECTO AL V-MODEL. No se realizará una asignación
         * correcta por defecto.
         *
         * Solución 1: Reasignasion manual
         * Solución 2: watch
         *
         * Puesto que esta lógina solo ocurre en este metodo se ha optado por la solución 1
         */
      }
    },
    /**
     * Método para comprobar que no se envíen cadenas vacías en el porcentaje y resetear el volumen en dicho caso
     * (Asociado al evento blur de los porcentajes)
     * @param {Number} index índice del array de datos que hay que modificar
     * @param {Boolean} mode indica si se ha de modificar el maximo (true) o el mínimo (false)
     * @param {FocusEvent} e evento de tipo blur
     */
    checkEmptyPercent(index, mode, e) {
      //Realizamos la comprobacion del valor del input
      if (e.target.value == "" || isNaN(e.target.value)) {
        //Lo establecemos a cero
        e.target.value = 0;
        //Cambiamos el valor del dato local dependiendo del modo
        if (mode) {
          this.datos[index][this.selectedStartDate].volMax = 0;
        } else {
          this.datos[index][this.selectedStartDate].volMin = 0;
        }
        //Actualizamos el contenedor de VUEX
        ///this.v_updVolumes(this.datos);
        //console.log(this.v_volumes);
        this.$emit('updateVolumesValues', index, this.selectedStartDate, this.datos[index][this.selectedStartDate])
      }
    },
    /**
     * Método para comprobar que no se envíen cadenas vacías en el volumen y resetear el porcentaje en dicho caso
     * (Asociado al evento blur de los volumenes)
     * @param {Number} index índice del array de datos que hay que modificar
     * @param {Boolean} mode indica si se ha de modificar el maximo (true) o el mínimo (false)
     * @param {FocusEvent} e evento de tipo blur
     */
    checkEmptyVolume(index, mode, e) {
      //Realizamos la comprobacion del valor del input
      if (e.target.value == "" || isNaN(e.target.value)) {
        //Lo establecemos a cero
        e.target.value = 0;
        //Cambiamos el valor del porcentaje asociado y el dato local dependiendo del modo
        if (mode) {
          this.datos[index][this.selectedStartDate].volMax = 0;
          document.getElementById(`${this.id}-volMaxPercent-${index}`).value = 0;
        } else {
          this.datos[index][this.selectedStartDate].volMin = 0;
          document.getElementById(`${this.id}-volMinPercent-${index}`).value = 0;
        }
        //Actualizamos el contenedor de VUEX
        ///this.v_updVolumes(this.datos);
        //console.log(this.v_volumes);
        this.$emit('updateVolumesValues', index, this.selectedStartDate, this.datos[index][this.selectedStartDate])
      }
    },
    /**
     * Método para realizar los cambios de estilos relacionados con las COLUMNAS de la tabla
     * (Asociado a los eventos mouseenter y mouseleave de todos los elementos de tipo celda)
     * @param {Number} index índice de la columna a la que hay que aplicar estilos
     * @param {Boolean} mode indica si se han de aplicar estilos de entrada (true) o salida (false) del mouse.
     */
    columnHover(index, mode) {
      //Obtención de elementos de columna según el índice
      let hoveredEmbalse = document.getElementById(`${this.id}-embalse-${index}`);
      let hoveredCapacidad = document.getElementById(`${this.id}-capacidad-${index}`);
      let hoveredVolMin = document.getElementById(`${this.id}-volMin-${index}`);
      let hoveredVolMax = document.getElementById(`${this.id}-volMax-${index}`);
      //Dependiendo de si entramos o salimos de la celda aplicamos unos estilos u otros
      if (mode) {
        hoveredEmbalse.style.color = "rgb(255, 255, 255)";
        hoveredEmbalse.style.backgroundColor = "rgb(39, 72, 129)";
        hoveredCapacidad.style.backgroundColor = "#ececec";
        hoveredVolMin.style.backgroundColor = "#ececec";
        hoveredVolMax.style.backgroundColor = "#ececec";
      } else {
        hoveredEmbalse.style.color = "rgb(255, 255, 255)";
        hoveredEmbalse.style.backgroundColor = "rgb(105, 107, 124)";
        hoveredCapacidad.style.backgroundColor = "rgb(255, 255, 255)";
        hoveredVolMin.style.backgroundColor = "rgb(255, 255, 255)";
        hoveredVolMax.style.backgroundColor = "rgb(255, 255, 255)";
      }
    },

    //Método que determina si un caracter es o no prohibido
    isForbiddenChar(char) {
      //Los caracteres prohibidos son cualquiera que no sea un dígito o un punto
      return isNaN(char) && char != ".";
    },

    //Funcion para copiar el objeto y que se deslinke la misma referencia en memoria
    copyObject(obj){
      return JSON.parse(JSON.stringify(obj));
    },
  //Función que recibe un array de objetos para ordenar, un array que contiene el orden deseado, y el nombre del 
  //atributo del objeto por el que se quiere ordenar. Devuelve un array ordenado.
    orderEmbalses(array, order, key){
      array.sort( function (a, b) {
        var A = a[key], B = b[key];
        if (order.indexOf(A) > order.indexOf(B)) {
          return 1;
        } else {
          return -1;
        }
      });
    return array;
    }
  },
  async mounted() { },
};
</script>

<style scoped>
/* Clase para que las anotaciones tengan el mismo estilo que los Select*/
.form-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #333333;
}
/* Inicio de estilos de la tabla personalizada*/
/* Se quitan los bordes de la primera celda*/

.table-custom .no-line {
  border: none;
  background-color: white;
}
/*Estilos de las cabeceras*/
.table-custom th {
  font-size: 10pt;
  background-color: rgb(105, 107, 124);
  color: rgb(255, 255, 255);
}
/*Estilos de las filas pares e impares para hacer striped*/
/*
.table-custom tbody tr:nth-child(odd) {
  background-color: rgb(255, 255, 255);
}
.table-custom tbody tr:nth-child(even) {
  background-color: rgb(244, 244, 244);
}
*/
/*Hover para cambiar la cabecera de las filas*/
.table-custom tbody tr:hover > th {
  color: rgb(255, 255, 255);
  background-color: rgb(39, 72, 129);
}
.table-custom thead tr > th {
  border: 1px solid #ccc;
}
.table-custom tbody tr > th {
  border: 1px solid #ccc;
}

.table-custom tbody {
  border-top: 1px;
}

.table-custom tbody tr > td {
  border: 1px solid #ccc;
}
/* Clase para los inputs de la tabla */
.inputVolume {
  width: 85px;
}
/* Hover para los inputs de la tabla */
.inputVolume:hover {
  box-shadow: 0 0 20px rgb(68, 99, 255);
}
/* Clase para poner el cursor en default */
.cursor-default {
  cursor: default;
}

.dateStartCell {
  background-color: #d9d7d7 !important;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 0.9rem;
}
</style>