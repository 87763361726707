<template>
  <div :class="viewMode == true ? 'grayscale' : ''">
    <div class="table-responsive">
      <table class="table table-custom table-hover">
        <thead class="text-center">
          <tr>
            <td class="no-line"></td>
            <th
              v-for="(mes, index) of meses"
              :key="index"
              colspan="2"
              :id="`mes-${index}`"
            >
              {{ mes }}
            </th>
          </tr>
          <tr>
            <td class="no-line"></td>
            <th
              v-for="(dia, index) of quincenas"
              :key="index"
              :id="`quincena-${index}`"
            >
              {{ dia }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, name, index) in embalses" :key="index">
            <th class="text-left">
              <div style="width: 100px">{{ name }}</div>
            </th>
            <td
              v-for="(dato, index) of value"
              :key="index"
              :class="[
                viewMode ? '' : ' cursor-pointer ',
                'text-center ' + cellColor(dato),
              ]"
              @click="changeAvailability(value, index)"
              @mouseenter="columnHoverEnter(index)"
              @mouseleave="columnHoverLeave(index)"
            >
              <div class="cellAvl"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-2 d-flex justify-content-center">
      <div class="col-lg-3 col-sm-6 d-flex justify-content-sm-around">
        <div class="d-flex">
          <div class="cellLegend cellAvlG"></div>
          <span class="ms-1 me-4 form-label">Muy Alta</span>
        </div>
        <div class="d-flex">
          <div class="cellLegend cellAvlY"></div>
          <span class="ms-1 me-4 form-label">Alta</span>
        </div>
      </div>
      <div
        class="col-lg-3 col-sm-6 d-flex justify-content-sm-around mt-1 mt-sm-0"
      >
        <div class="d-flex">
          <div class="cellLegend cellAvlO"></div>
          <span class="ms-1 me-4 form-label">Media</span>
        </div>
        <div class="d-flex ms-3 ms-sm-0">
          <div class="cellLegend cellAvlR"></div>
          <span class="ms-1 form-label">Baja</span>
        </div>
      </div>
      <div
        class="col-lg-2 col-sm-6 d-flex justify-content-sm-around mt-1 mt-sm-0"
      >
        <div class="d-flex ms-3 ms-sm-0">
          <div class="cellLegend cellAvlD"></div>
          <span class="ms-1 form-label">Nula</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PrevisionService from "@/services/PrevisionService";

export default {
  data() {
    return {
      isMounted: false,
      embalses: {
        Aracena: [],
        Zufre: [],
        "La Minilla": [],
        Cala: [],
        "El Gergal": [],
        "Los Melonares": [],
      },
      meses: [
        "ENE",
        "FEB",
        "MAR",
        "ABR",
        "MAY",
        "JUN",
        "JUL",
        "AGO",
        "SEP",
        "OCT",
        "NOV",
        "DIC",
      ],
      quincenas: [
        "01",
        "15",
        "01",
        "15",
        "01",
        "15",
        "01",
        "15",
        "01",
        "15",
        "01",
        "15",
        "01",
        "15",
        "01",
        "15",
        "01",
        "15",
        "01",
        "15",
        "01",
        "15",
        "01",
        "15",
      ],
    };
  },
  components: {},
  props: {
    viewMode: {
      type: Boolean,
      default: false,
    },
    altAvaDataset: {
      type: Array,
    },
    reusePlan: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("planner", ["v_selectedYear", "v_reusePlan"]),
  },
  watch: {
    v_selectedYear: async function (newVal, oldVal) {
      if (newVal) {
        await this.getDisponibility();
      }
    },
    altAvaDataset: function (newVal, oldVal) {
      if (newVal) {
        this.getDisponibility();
      }
    },
    reusePlan: async function (newVal, oldVal) {
      if (newVal == true) {
        this.disponibility = await this.v_reusePlan.availability;
        await this.populateEmbalses();
      }
    },
  },
  methods: {
    ...mapActions("planner", ["v_updAvailability"]),
    async getDisponibility() {
      let param = await this.$route.query.reuseplan;
      if (param == "true") {
        this.disponibility = await this.v_reusePlan.availability;
        await this.populateEmbalses();
      } else {
        if (
          this.viewMode == true &&
          this.reusePlan == false &&
          this.altAvaDataset.length > 0
        ) {
          this.disponibility = this.altAvaDataset;
          this.populateEmbalses();
        } else if ((
          this.viewMode == false &&
          this.reusePlan == false &&
          this.isMounted == false) || (!this.v_reusePlan.availability)
        ) {
          let response = await PrevisionService.getAvailabilityCurrent(
            this.v_selectedYear
          );
          if (response.data) {
            this.disponibility = response.data;
            this.populateEmbalses();
          } else {
            console.log(
              "Error al recuperar los datos de disponibilidad de embalses"
            );
          }
        }
      }
      this.isMounted = true;
    },
    populateEmbalses() {
      this.embalses = {
        Aracena: [],
        Zufre: [],
        "La Minilla": [],
        Cala: [],
        "El Gergal": [],
        "Los Melonares": [],
      };
      for (let i = 0; i < this.disponibility.length; i++) {
        if (this.embalses[this.disponibility[i].Nombre_Embalse]) {
          if (this.embalses[this.disponibility[i].Nombre_Embalse].length < 24) {
            this.embalses[this.disponibility[i].Nombre_Embalse].push(
              this.disponibility[i].Disponibilidad
            );
          }
        } else {
          this.embalses[this.disponibility[i].Nombre_Embalse] = [];
          this.embalses[this.disponibility[i].Nombre_Embalse].push(
            this.disponibility[i].Disponibilidad
          );
        }
      }
      this.v_updAvailability(this.embalses);
    },
    changeAvailability(rowValues, index) {
      if (this.viewMode == false) {
        if (rowValues[index] == 4) {
          rowValues[index] = 0;
        } else {
          rowValues[index]++;
        }
        this.v_updAvailability(this.embalses);
      }
    },
    debug() {
      console.log(this.embalses);
    },
    cellColor(dato) {
      switch (dato) {
        case 0:
          return "cellAvlG";
        case 1:
          return "cellAvlY";
        case 2:
          return "cellAvlO";
        case 3:
          return "cellAvlR";
        case 4:
          return "cellAvlD";
      }
    },
    columnHoverEnter(index) {
      let hoveredQuincena = document.getElementById(`quincena-${index}`);
      let hoveredMes = document.getElementById(`mes-${Math.floor(index / 2)}`);
      hoveredQuincena.style.color = "rgb(255, 255, 255)";
      hoveredQuincena.style.backgroundColor = "rgb(39, 72, 129)";
      hoveredMes.style.color = "rgb(255, 255, 255)";
      hoveredMes.style.backgroundColor = "rgb(39, 72, 129)";
    },
    columnHoverLeave(index) {
      let hoveredQuincena = document.getElementById(`quincena-${index}`);
      let hoveredMes = document.getElementById(`mes-${Math.floor(index / 2)}`);
      hoveredQuincena.style.color = "rgb(255, 255, 255)";
      hoveredQuincena.style.backgroundColor = "rgb(105, 107, 124)";
      hoveredMes.style.color = "rgb(255, 255, 255)";
      hoveredMes.style.backgroundColor = "rgb(105, 107, 124)";
    },
  },
  async created() {
    // Forzar el populado en caso de que la variable reactiva no se dispare a la hora dle montaje
    // Podemos usar una variable intermedia para detectar qeu está populado o bien coger algun elemento
    // del objeto para sobreentender si está vacío o lleno
    if (this.embalses.Aracena.length < 1) {
      await this.getDisponibility();
    }
  },
};
</script>

<style scoped>
/* Clase para que las anotaciones tengan el mismo estilo que los Select*/
.form-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #333333;
}

/* Inicio de estilos de la tabla personalizada*/
/* Se quitan los bordes de las dos primeras celdas*/
table {
  border-radius: 0.5rem !important;
}
.table-custom td,
.table-custom th {
  border: 1px rgb(182, 182, 182) solid;
}

.table-custom .no-line {
  border: none;
  background-color: white;
}
/*Estilos de las cabeceras*/
.table-custom th {
  font-size: 10pt;
  background-color: rgb(105, 107, 124);
  color: rgb(255, 255, 255);
}
/*Estilos de las filas pares e impares para hacer striped*/
.table-custom tbody tr:nth-child(odd) {
  background-color: rgb(255, 255, 255);
}
.table-custom tbody tr:nth-child(even) {
  background-color: rgb(244, 244, 244);
}
/*Hover para cambiar la cabecera de las filas*/
tbody tr:hover > th {
  color: rgb(255, 255, 255);
  background-color: rgba(39, 72, 129);
}
.cellCont {
  background-color: red;
}
.cellAvl {
  color: rgb(255, 255, 255);
  font-weight: 500;
  width: 15px;
  height: 15px;
}

.table-custom tbody td:hover {
  box-shadow: inset 0 0 8px rgba(39, 72, 129, 0.75);
}
.cellAvlG {
  background: rgb(127, 186, 98);
  background: linear-gradient(
    180deg,
    rgb(127, 186, 98) 0%,
    rgb(69, 128, 39) 100%
  );
}
.cellAvlY {
  background: rgb(255, 255, 109);
  background: linear-gradient(
    180deg,
    rgb(255, 255, 109) 0%,
    rgb(204, 201, 38) 100%
  );
}
.cellAvlO {
  background: rgb(255, 173, 40);
  background: linear-gradient(
    180deg,
    rgb(255, 173, 40) 0%,
    rgb(169, 115, 28) 100%
  );
}
.cellAvlR {
  background: rgb(255, 84, 41);
  background: linear-gradient(
    180deg,
    rgb(255, 84, 41) 0%,
    rgb(179, 35, 19) 100%
  );
}
.cellAvlD {
  background: rgb(73, 72, 72);
  background: linear-gradient(180deg, rgb(73, 72, 72) 0%, rgb(30, 29, 29) 100%);
}
.cellLegend {
  outline: 2px solid rgba(56, 56, 56, 0.697);
  border-radius: 5px;
  width: 25px;
  height: 25px;
}
</style>