<template>
  <div class="row col-12 cursor-pointer">
    <div class="col-12 col-sm-3 ms-0">
      <Select
        :class="viewMode == true ? 'grayscale' : ''"
        v-model="selectedYear"
        id="yearSelector"
        icon="far fa-calendar-alt"
        label="* Año de aducción"
        :options="yearsList"
        valKey="name"
        textKey="name"
        @change="updateSelectedYear"
        :disabled="viewMode == true ? true : false"
      />
    </div>
    <div class="col-12 col-sm-4 ms-0 ms-sm-4">
      <Select
        :class="viewMode == true ? 'grayscale' : ''"
        v-model="selectedCriteria"
        id="criteriaSelector"
        icon="fas fa-bezier-curve"
        label="* Criterio de optimización"
        :options="criteriaList"
        valKey="key"
        textKey="name"
        style="min-width: 275px"
        :disabled="viewMode == true ? true : false"
      />
    </div>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import PlannerService from "@/services/PlannerService";
import PrevisionService from "@/services/PrevisionService";
import Select from "@/components/commons/Select";

export default {
  data() {
    return {
      selectedYear: null,
      yearsList: [],
      selectedCriteria: null,
      criteriaList: [],
      disponibility: [],
      startDateList: [],
      selectedStartDate: null,
    };
  },
  props: {
    viewMode: {
      type: Boolean,
      default: false,
    },
    reusePlan: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Select,
  },
  watch: {
    selectedYear: function (newVal, oldVal) {
      if (newVal != null) {
        this.v_updSelectedYear(newVal);
      }
    },

    selectedCriteria: function (newVal, oldVal) {
      if (newVal != null) {
        this.v_updSelectedCriteria(newVal);
      }
    },

    startDate: function (newVal, oldVal) {
      if (newVal != null) {
        this.v_updSelectedStartDate(newVal);
      }
    },

    v_selectedYear: function (newVal, oldVal) {
      if (newVal != null && this.viewMode == true) {
        this.selectedYear = newVal;
      }
    },

    v_selectedCriteria: function (newVal, oldVal) {
      if (newVal != null && this.viewMode == true) {
        this.selectedCriteria = newVal;
      }
    },

    v_selectedStartDate: function (newVal, oldVal) {
      if (newVal != null && this.viewMode == true) {
        this.selectedStartDate = newVal;
      }
    },
  },
  computed: {
    ...mapState("planner", [
      "v_uuid",
      "v_selectedYear",
      "v_selectedCriteria",
      "v_selectedStartDate",
    ]),
  },
  methods: {
    ...mapActions("planner", [
      "v_updSelectedYear",
      "v_updSelectedCriteria",
      "v_updSelectedStartDate",
    ]),
    updateSelectedYear() {
      this.v_updSelectedYear(this.selectedYear);
    },
  },
  async mounted() {
    /* Seleccionar elemento por defecto al cargar */
    let response_01 = await PlannerService.getFormsOptions();
    let response_02 = await PrevisionService.getYearsPrevActual();

    if (response_02.data) {
      // Populate selects
      this.yearsList = response_02.data;
      //Con este pop quitamos el ultimo año del select para que el usuario no pueda hacer planes sobre él, ya que al no
      //haber planes previos, el algoritmo no funcionará.
      this.yearsList.pop();

      // En caso de que el plan sea nuevo
      ////////////////////////////////////
      if (this.reusePlan != true || this.v_selectedYear == null) {
        // Select defaults
        this.selectedYear = await this.yearsList[0].name;
        // Lo guardamos reactivamente para que se popule por todos sitios
        await this.updateSelectedYear();
      } else {
        this.selectedYear = await this.v_selectedYear;
      }
    } else {
      console.log(
        "No se pudo obtener datos desde el backend. Contacte con el desarrollador"
      );
    }

    if (response_01.data) {
      // Populate selects
      this.criteriaList = response_01.data.criteria;
      this.startDateList = response_01.data.startDates;

      // En caso de que el plan sea nuevo
      ////////////////////////////////////
      if (this.reusePlan != true || this.v_selectedCriteria == null) {
        // Select defaults
        this.selectedCriteria = await this.criteriaList[0].key;
        this.selectedStartDate = await this.startDateList[0].key;
      } else {
        this.selectedCriteria = await this.v_selectedCriteria;
      }
    } else {
      console.log(
        "No se pudo obtener datos desde el backend. Contacte con el desarrollador"
      );
    }
  },
};
</script>

<style scoped>
/* Sobreescribir configuraciones por defecto */
.card {
  max-width: 100%;
}

.nav-tabs .nav-link {
  color: gray;
  border: 0;
  border-bottom: 1px solid grey;
}

.nav-tabs .nav-link:hover {
  border: 0;
  border-bottom: 1px solid grey;
}

.nav-tabs .nav-link.active {
  color: #000000;
  border: 0;
  border-radius: 1;
  border-bottom: 2px solid #4ca1ef;
  background-color: #cde9ff;
}

.nav-item:hover {
  background-color: #f0f7fc;
  transition: background-color 0.5s ease;
}

.grayscale {
  opacity: 0.7;
  filter: grayscale(70%);
}

.form-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #333333;
}
</style>